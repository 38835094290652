import React, { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import Img from 'react-cloudinary-lazy-image'
import { Marker } from 'react-leaflet';
import GMap from '../components/GMap';
import LeafletMap from '../components/LeafletMap';

const ContactTemplate = (section) => {
  //const contactSection = section.item;

  const data = useStaticQuery(graphql`
  query ContactPage {
    markdownRemark(frontmatter: {templateKey: {eq: "pageTemplate"}, section: {elemMatch: {type: {eq: "section_contact"}}}}) {
          id
          frontmatter {
            title
            section {
              title
              headingType
              className
              containerType
              text1
              lat
              lng
              markerText
              imageHeader
              imageLogo
            }
          }
        }
      }
`);

const GMapOrLeaflet = 1;
  const { markdownRemark: contact } = data;
  const contactInfo = contact.frontmatter.section[0];

  const position = {lat: contactInfo.lat, lng: contactInfo.lng}
  const markerText = contactInfo.markerText;
  const markerRef = useRef();

  var imgHeader = contactInfo.imageHeader;
  var imgHeaderSegment = imgHeader.split('/');
  var imgHeaderFile = imgHeaderSegment.pop();
  var imgHeaderFolder = imgHeaderSegment.slice(imgHeaderSegment.findIndex(f => f === 'upload') + 2, imgHeaderSegment.length).join("/");

  var imgLogo = contactInfo.imageLogo;
  var imgLogoSegment = imgLogo.split('/');
  var imgLogoFile = imgLogoSegment.pop();
  var imgLogoFolder = imgLogoSegment.slice(imgLogoSegment.findIndex(f => f === 'upload') + 2, imgLogoSegment.length).join("/");


  const DEFAULT_ZOOM = 15;

  const mapSettings = {
    center: position,
    zoom: DEFAULT_ZOOM,
    markerText: markerText
  };

  return (
    <section className="section pb-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <Img
              cloudName={'groupea'}
              imageName={imgHeaderFolder + '/' + imgHeaderFile}
              fluid={{
                maxWidth: 1600
              }}
              style={{
                width: '100vw',
                height: '40vh'
              }}
              alt={contactInfo.title}
              quality="best"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <h4 className="mt-4">
          <b style={{ color: '#c80000' }}>/</b>&nbsp;
        <span className="contact-title">{contactInfo.title}</span>
        </h4>
        <div className="row">
          <div className="col-md-6 col-xl-4 mt-4">
            <div>
              <Img
                cloudName={'groupea'}
                imageName={imgLogoFolder + '/' + imgLogoFile}
                fluid={{
                  maxWidth: 300
                }}
                style={{ maxWidth: '300px', height: '' }}
                alt={contactInfo.title}
                quality="best"
              />
            </div>
            <div className="mt-2">
              {contactInfo.text1 &&
                <ReactMarkdown
                children={contactInfo.text1.replace(/\\/gi, '  ')}
                  escapeHtml={false}
                />   }
                </div>        
          </div>
          <div className="col-md-6 col-xl-8">
          { GMapOrLeaflet === 1 ?
          <GMap {...mapSettings}></GMap>
          : <LeafletMap {...mapSettings}>
        <Marker ref={markerRef} position={position} />
              </LeafletMap>
}
        </div>
        </div>
      </div>
    </section>
  )
}

export default ContactTemplate