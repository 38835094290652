import React, { Fragment } from 'react'
import { v4 } from 'uuid'
import { graphql, useStaticQuery } from 'gatsby'
import { TeamTemplate01, TeamTemplate02 } from '../components/Team/'


const TeamTemplate = (section) => {
  const team = section.item;

  const data = useStaticQuery(graphql`
    query TeamByName {
    allTeamMembers: allMarkdownRemark(filter: {frontmatter: {collectionKey: {eq: "members"}}}, sort: {fields: [frontmatter___position], order: ASC}) {
      edges {
        node {
          id
          frontmatter {
            title
            nom
            position
            photo
            team
            description
          }
        }
      }
    }
    teamTemplates: allMarkdownRemark(filter: {frontmatter: {collectionKey: {eq: "team"}}}) {
      edges {
        node {
          frontmatter {
            title
            templateId
          }
        }
      }
    }
  }
`);

  const { allTeamMembers, teamTemplates } = data;
  const teamMembers = allTeamMembers.edges.filter(x => x.node.frontmatter.team === team.team)
  const templateId = teamTemplates.edges.find(x => x.node.frontmatter.title === team.team).node.frontmatter.templateId

  return (
    <section className="section pb-4">
      <div className="container-fluid">
        <h4 className="mx-4 mt-4">
          <b style={{ color: '#c80000' }}>/</b>&nbsp;
        {team.title}
        </h4>
        <div className="row">
          {teamMembers && teamMembers.map(({ node: item }, index) => {           
            return (
              <Fragment key={v4()} >
              {{
                1: <TeamTemplate01 item={item}></TeamTemplate01>,
                2: <TeamTemplate02 item={item} index={index} className="pb-3"></TeamTemplate02>
              }[templateId]}
              </Fragment>
            )
          }
          )}
        </div>
      </div>
    </section>
  )
}

export default TeamTemplate