import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import BlockTemplate from './blockTemplate'
import { v4 } from 'uuid'
import TeamTemplate from './teamTemplate'
import ProjectHomeTemplate from './projectHomeTemplate'
import ContactTemplate from './contactTemplate'
import NewsTemplate from './newsTemplate'

const PageTemplate = ({ data, pageContext }) => {
  const { markdownRemark: page } = data
  const section = page.frontmatter.section;

  return (
    <Layout pageContext={pageContext}>
      <Fragment>
        <section className="section">
          <div className="container-fluid">

            {section && section.sort((a, b) => a.position - b.position).map((item, index) =>
            <div key={v4()}>
            {{
              'section_block': <BlockTemplate item={item}></BlockTemplate>,
              'section_team': <TeamTemplate item={item}></TeamTemplate>,
              'section_contact': <ContactTemplate item={item}></ContactTemplate>,
              'section_project': <ProjectHomeTemplate item={item}></ProjectHomeTemplate>,
              'section_news': <NewsTemplate item={item}></NewsTemplate>
            }[item.type]}     
            </div>     
            )}
          </div>
        </section>
      </Fragment>
    </Layout>
  )
}

export default PageTemplate

export const PageQuery = graphql`
  query PageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        className
        section {       
          title
          type
          headingType
          className
          containerType
          position
          block {
            position
            title
            className
            body 
            blockImage
          }
          team
          favorites
          body
          }
      }
    }
  }
`
