import React, { Fragment } from 'react'
import ReactMarkdown from 'react-markdown'
import Img from 'react-cloudinary-lazy-image'

const BlockTemplate = (section) => {
    const ConditionalWrapper = ({ condition, wrapper, children }) =>
        condition ? wrapper(children) : children;

    const item = section.item
    return (
        <div className={(item.containerType === 'mixte' ? 'container-fluid' : item.containerType) + ' ' + item.className}>
            <div className="row" >
                <ConditionalWrapper
                    condition={item.containerType === 'mixte'}
                    wrapper={children => <div className="container" ><div className="row" >{children}</div></div>}
                >
                    <div className={'col ' + (item.title ? 'pb-5' : '')}>
                        {item.title && item.title !== '' &&
                            <div className={(item.className ?? '') === '' ? 'py-3' : item.className } >
                                {{
                                    h1: <h1>{item.title}</h1>,
                                    h2: <h2>{item.title}</h2>,
                                    h3: <h3>{item.title}</h3>,
                                    h4: <h4>{item.title}</h4>,
                                    h5: <h5>{item.title}</h5>,
                                    h6: <h6>{item.title}</h6>
                                }[item.headingType]}
                                {item.subTitle && item.subTitle !== '' && <h5>{item.subTitle}</h5>}
                            </div>
                        }
                        {item.body}

                        <div className="row">
                            {item.block && item.block.map((block, index) => {
                                var imgSegment = block.blockImage?.split('/');
                                var imgFile;
                                var imgFolder;

                                if (imgSegment) {
                                    imgFile = imgSegment.pop();
                                    imgFolder = imgSegment.pop();
                                }
                                return (
                                    <Fragment key={index}>
                                        {block.body ?
                                            <div className={'col ' + block.className}>
                                                {block.body && <ReactMarkdown
                                                    children={block.body.replace(/\\/gi, '  ')}
                                                    escapeHtml={false}
                                                />
                                                }
                                            </div> : null}
                                        {imgSegment ?
                                            <div className={'col ' + block.className}>
                                                <Img
                                                    cloudName={'groupea'}
                                                    imageName={imgFolder + '/' + imgFile}
                                                    fluid={{
                                                        maxWidth: 1280
                                                    }}
                                                    alt={block.alt}
                                                    urlParams={'g_south,c_fill'}
                                                    quality="best"
                                                />

                                            </div> : null}
                                    </Fragment>)
                            }
                            )}
                        </div>
                    </div>
                </ConditionalWrapper>
            </div>
        </div>
    )
}

export default BlockTemplate