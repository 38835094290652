import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Card from '../components/Card';
import '../stylesheets/news.css'

const NewsTemplate = (section) => {
  const data = useStaticQuery(graphql`
  query NewsHomePage {
    allNews: allMarkdownRemark(filter: {frontmatter: {collectionKey: {eq: "news"}}}, sort: {fields: [frontmatter___position], order: ASC}) {
      edges {
        node {
          id
          frontmatter {
            titre
            url
            position
            photo
            datePublication
            description            
          }
        }
      }
      }
    }
`);

  const { allNews } = data;
 
  const newsData = allNews.edges;
  return (
    <section className="section pb-4">
      <div className="container">
        <div className="row">
          <div className="container px-6 mx-auto my-6 md:my-8 xl:my-12">
              <h1 className="uppercase">Actualités</h1>
              <div className="grid grid-cols-1 gap-x-3 gap-y-12 mt-8 sm:grid-cols-2 lg:grid-cols-3">
                  {newsData && newsData.map(({ node: item }, index) => ( 
                   <div key={index}> 
                      <Card
                          key={item.id}
                          href={item.frontmatter.url}
                          image={item.frontmatter.photo}
                          date={item.frontmatter.datePublication}
                          description={item.frontmatter.titre}
                          bgColor="#8c8c8c"
                          textColor="#ffffff"
                      />
                      </div>
                  ))}
              </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NewsTemplate