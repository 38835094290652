import React from 'react'
import { Link } from "gatsby";
import Moment from 'react-moment';
import Img from 'react-cloudinary-lazy-image';

export default function Card({image, date, description, bgColor, textColor, href}) {
    var imgSegment = image?.split('/');
    var imgFile;
    var imgFolder;

    if (imgSegment) {
        imgFile = imgSegment.pop();
        imgFolder = imgSegment.pop();
    }
    return (
        <Link to={href} style={{textDecoration: 'none'}} className="relative">
            {/* Vertical date */}
            <div
                className="absolute top-0 left-0 text-xs font-medium text-gray-600">
                <span className="block -ml-6 transform -rotate-90 translate-y-6">
                <Moment format="DD.MM.YYYY">
                  {date}
                </Moment>
                    </span>
            </div>

            {/* Card with image and description */}
            <div className="ml-6 image-box" >
                <div className="image-box-img">
                <Img
                cloudName={'groupea'}
                imageName={imgFolder + '/' + imgFile}
                fluid={{
                    maxWidth: 600,
                    height: 400
                }}
                alt={description}
                urlParams={'g_north,c_fill'}
                quality="best"
            />
            </div>
                <div
                    className={`px-6 py-4`} style={{height: '130px', backgroundColor: bgColor, color: textColor, minWidth: '100px'}}>
                    <p className={`text-sm font-semibold uppercase line-clamp-3 leading-relaxed tracking-widest `}>
                        {description}
                    </p>
                </div>
            </div>
        </Link>
    )
}
