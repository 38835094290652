import React, { Component } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

export default class LeafletMap extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
}
  componentDidMount() { 
    const map = this.mapRef.current.leafletElement; 
    setTimeout(() => { 
        map.invalidateSize(); 
    }, 250); 
};

  render() {
    const { children, center, zoom } = this.props;

    if (typeof window !== 'undefined') {
      return (
        <MapContainer ref={this.mapRef} center={center} zoom={zoom} style={{ width: '100%', height: '300px' }}>
          {children}
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </MapContainer>
      );
    } else {
      return null;
    };
  };
}