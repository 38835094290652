import React, { Component } from 'react'
import Img from 'react-cloudinary-lazy-image'

export class TeamTemplate01 extends Component {
  render() {
    const { item } = this.props;

    var employe = item.frontmatter;
    var imgSegment = employe.photo.split('/');
    var imgFile = imgSegment.pop();
    var imgFolder = imgSegment.pop();

    return (
    <div className="col-6 col-md-4 col-lg-3 col-xl-2">
                  <div className="col-12" >
                    <Img
                      cloudName={'groupea'}
                      imageName={imgFolder + '/' + imgFile}
                      fluid={{
                        maxWidth: 625
                      }}
                      alt={employe.nom}
                      urlParams={'g_south,c_fill'}
                      quality="best"
                    />
                    <div className="col-12 text-right pt-3 pb-1 employe-descr">
                      <h4 style={{fontSize: '1.1rem'}}>{employe.nom}<b style={{ color: '#c80000' }}>&nbsp;\</b>&nbsp;</h4>
                      <h5 style={{fontSize: '0.8rem'}}>{employe.title}</h5>
                    </div>
                  </div>
                </div>
    )
}
}