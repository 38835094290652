import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';


const Marker = ({ text }) => <div>
    <a href="https://goo.gl/maps/4ycWKVLViVrRSrYR9" target="_blank" rel="noopener noreferrer">
<div className="map-marker-text" style={{fontSize:'18px', width: '100vw', marginLeft: '-60px', marginTop: '-20px'}}>    
{text}
</div>
<img src="https://res.cloudinary.com/groupea/image/upload/Arrow_3.png" alt="Google Maps Marker"
className="map-marker-image" 
style={{width: '48px', height:'48px'}}></img>
</a>
</div>


class GMap extends Component {
    createMapOptions = () => ({
          panControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          styles: [
            {
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "gamma": 1
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.business",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.business",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.place_of_worship",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.place_of_worship",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "water",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "saturation": 50
                    },
                    {
                        "gamma": 0
                    },
                    {
                        "hue": "#50a5d1"
                    }
                ]
            },
            {
                "featureType": "administrative.neighborhood",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#333333"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "weight": 0.5
                    },
                    {
                        "color": "#333333"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "gamma": 1
                    },
                    {
                        "saturation": 50
                    }
                ]
            }
        ]      
      })
       

  render() {
   
    return (
    <div className="map-container">
      <div className="map">
        <GoogleMapReact options={this.createMapOptions}
          bootstrapURLKeys={{ key: 'AIzaSyCFduTZG8yklWWrK4UsUKaUlYNxXKWhny4' }}          
          defaultCenter={[this.props.center.lat, this.props.center.lng]}
          defaultZoom={this.props.zoom}          
        >
          <Marker
            lat={this.props.center.lat}
            lng={this.props.center.lng} 
            text={this.props.markerText}
            color="#c80000"           
          />
           
        </GoogleMapReact>
      </div>
      </div>
    );
  }
}
 
export default GMap;